import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
const queryString = require('query-string')
import _isEqual from 'lodash/isEqual'

// This hook is a two-way bind between url queries and state queries.
// This does not include the queries generated by the slug pages

const useNextQuery = (initialQuery = {}, shallow = true) => {
  const { asPath, push, pathname } = useRouter()
  const [state, setState] = useState(() => {
    const { query: initialRouteQuery } = queryString.parseUrl(asPath)
    return { ...initialQuery, ...initialRouteQuery }
  })

  useEffect(() => {
    const indexOfQuery = asPath.indexOf('?')
    const newPathname =
      indexOfQuery < 1 ? asPath : asPath.slice(0, indexOfQuery)

    const { query } = queryString.parseUrl(asPath)
    if (_isEqual(query, state)) return
    push(
      { pathname: pathname, query: state },
      { pathname: newPathname, query: state },
      { shallow: shallow }
    )
  }, [state])

  useEffect(() => {
    const { query } = queryString.parseUrl(asPath)
    setState(query)
  }, [asPath])

  const addQueries = (newQueries: any) =>
    setState((prevState: any) => ({ ...prevState, ...newQueries }))

  const deleteQuery = (oldQuery: any) =>
    setState((prevState: any) => {
      const { [oldQuery]: deletedQuery, ...rest } = prevState

      return rest
    })

  const addQuery = ({
    key,
    value,
  }: {
    key: string
    value: string | Array<string>
  }) => {
    if (!state[key]) return addQueries({ [key]: value })
    if (typeof state[key] === 'string' || typeof state[key] === 'number') {
      if (Array.isArray(value)) {
        addQueries({
          [key]: [state[key], ...value],
        })
      } else {
        addQueries({
          [key]: [state[key], value],
        })
      }
    } else if (Array.isArray(value)) {
      addQueries({ [key]: [...state[key], ...value] })
    } else {
      addQueries({ [key]: [...state[key], value] })
    }
  }

  const reduceQuery = ({
    key,
    value,
  }: {
    key: string
    value: string | Array<string>
  }) => {
    if (!state[key]) return
    if (typeof state[key] === 'string') {
      deleteQuery(key)
    } else if (Array.isArray(value)) {
      addQueries({
        [key]: state[key].filter(
          (val: number | string) =>
            value.filter((value) => value === val)?.length <= 0
        ),
      })
    } else {
      addQueries({
        [key]: state[key].filter((val: number | string) => val !== value),
      })
    }
  }

  const replaceQuery = ({
    key,
    value,
  }: {
    key: string
    value: string | Array<string>
  }) => {
    addQueries({
      [key]: value,
    })
  }

  const clearQueries = () => setState({})

  return {
    queries: state,
    addQueries,
    addQuery,
    deleteQuery,
    replaceQuery,
    reduceQuery,
    clearQueries,
  }
}

export default useNextQuery
