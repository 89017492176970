import { useRef, useEffect } from 'react'
import useOutsideClickAlerter from '../../hooks/useOutsideClickAlerter'

export default function OutsideClickAlerter(props: any) {
  const wrapperRef = useRef<any>(null)
  const { outsideClick } = useOutsideClickAlerter(wrapperRef)
  const { children, callback, xlHidden, className, style } = props

  useEffect(() => {
    if (outsideClick) {
      setTimeout(() => {
        callback()
      }, 200)
    }
  }, [outsideClick]) // eslint-disable-line
  return (
    <div
      ref={wrapperRef}
      className={`${xlHidden ? 'xl:hidden' : ''} ${className}`}
      style={style}
    >
      {children}
    </div>
  )
}
