import React, { ReactNode } from 'react'
import LoadingSpinner from '../../elements/LoadingSpinner'

export type StatusTypes = 'success' | 'loading' | 'failed' | 'inactive'

const Status = ({
  status,
  children,
}: {
  status: StatusTypes
  children: ReactNode | string
}) => {
  if (status === 'success') return <>Success</>
  if (status === 'loading') return <LoadingSpinner />

  return <>{children}</>
}

export default Status
