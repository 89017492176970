import { FC, useContext } from 'react'
import { useNavigation } from '../../../../contexts/NavigationContext'
import { FaStar, FaRegStar } from 'react-icons/fa'
import { useRouter } from 'next/router'
import { AuthContext } from '../../../../contexts/AuthContext'

type FavoriteProps = {
  locationId: number
}

const Favorite: FC<FavoriteProps> = ({ locationId }) => {
  const { isAuthenticated } = useContext<any>(AuthContext)
  const { favoriteLocations, handleFavoriteLocations } = useNavigation()

  if (!isAuthenticated) return <></>

  const isChecked = favoriteLocations.has(locationId)

  return isChecked ? (
    <FaStar
      className={`h-5 w-5 text-bidfta-yellow`}
      onClick={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        await handleFavoriteLocations(locationId)
      }}
    />
  ) : (
    <FaRegStar
      className={`h-5 w-5 text-[#9b9ba4]`}
      onClick={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        await handleFavoriteLocations(locationId)
      }}
    />
  )
}

export default Favorite
