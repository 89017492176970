import React, { useEffect, useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import Title from '@components/MainLayout/Sidebar/reusable/Title'
import { useNavigation } from '../../../../contexts/NavigationContext'
import Link from 'next/link'
import { NavItemType } from '../../../../contexts/NavigationContext/types'
import classNames from 'classnames'

const Menu = () => {
  const { showSidebar, setShowSidebar, navigationLinks } = useNavigation()
  const defaultValue = {
    label: 'Menu',
    items: navigationLinks,
  }
  const [{ label: title, items }, setContent] =
    useState<NavItemType>(defaultValue)

  useEffect(() => {
    setTimeout(() => {
      if (!showSidebar) {
        setContent(defaultValue)
      }
    }, 500)
  }, [showSidebar])

  const linkClass =
    'p-6 border-b-2 flex hover:bg-bidfta-gray-light font-bold text-bidfta-blue-dark ease-in-out duration-200'

  return (
    <>
      <Title>{title}</Title>
      <ul>
        {items?.map((item) => {
          const { label, route, items: SubItems, isMobileOnly } = item

          return (
            <li key={label} className={isMobileOnly ? 'lg:hidden' : ''}>
              {route ? (
                <Link
                  href={route}
                  className={classNames('px-12', linkClass)}
                  onClick={() => setShowSidebar(false)}
                >
                  {label}
                </Link>
              ) : (
                <button
                  className={classNames(
                    ' pl-12 pr-8 w-full items-center justify-between',
                    linkClass
                  )}
                  onClick={() => setContent(item)}
                >
                  <p>{label}</p>
                  {SubItems ? <IoIosArrowForward size={24} /> : <></>}
                </button>
              )}
            </li>
          )
        })}
        {title === 'Menu' ? (
          <></>
        ) : (
          <li>
            <button
              onClick={() => setContent(defaultValue)}
              className={classNames(
                'gap-4 px-8 w-full items-center',
                linkClass
              )}
            >
              <IoIosArrowBack size={24} />
              <h3>Go Back</h3>
            </button>
          </li>
        )}
      </ul>
    </>
  )
}

export default Menu
