import { useEffect, useState } from 'react'
import Title from '@components/MainLayout/Sidebar/reusable/Title'
import { useNavigation } from '../../../../contexts/NavigationContext'
import { sortByDistance } from '@components/Location/utils/locationFunctions'
import LocationCard from '@components/Location/reusable/LocationCard'
import NoLocationsFound from '@components/Location/reusable/NoLocationsFound'
import styles from '@components/Location/Location.module.scss'
import ZipcodeForm, {
  FormData,
} from '@components/MainLayout/Sidebar/reusable/ZipcodeForm'
import { SubmitHandler } from 'react-hook-form'
import { getLocationByZipcode } from '@components/Location/server/getLocations'
import { Locations } from '@components/Location/location.types'
import { LocationType } from '../../../../contexts/NavigationContext/types'

const sortLocations = (
  allLocations: Locations,
  locationsNear: Locations | null,
  favoriteLocations: LocationType
) => {
  if (!locationsNear)
    return allLocations.filter(({ id }) => favoriteLocations.has(id))

  const favoriteLocationIdsNotInList = Array.from(favoriteLocations).filter(
    (id) => locationsNear.every((locationNear) => locationNear.id !== id)
  )
  const extraFavoriteLocations = allLocations.filter(({ id }) =>
    favoriteLocationIdsNotInList.includes(id)
  )

  return [...locationsNear, ...extraFavoriteLocations]
}

const UpdateWarehouse = () => {
  const {
    allLocations,
    locationsInArea,
    favoriteLocations,
    zipcode,
    handlePreferredZipcode,
  } = useNavigation()
  const [locations, setLocations] = useState<Locations>(
    sortLocations(allLocations, locationsInArea, favoriteLocations)
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setLocations(
      sortLocations(allLocations, locationsInArea, favoriteLocations)
    )
  }, [allLocations, locationsInArea, favoriteLocations])

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setErrorMessage(null)
    try {
      setIsSubmitting(true)
      const locationsNear = await getLocationByZipcode(data.zipcode)
      await handlePreferredZipcode(data.zipcode)
      if (locationsNear) {
        setLocations(
          sortLocations(allLocations, locationsNear, favoriteLocations)
        )
      } else {
        setErrorMessage('No Locations in Your Area')
      }
    } catch (error) {
      setErrorMessage('Error updating locations: ' + (error as Error)?.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Title>Update Warehouse</Title>
      <ZipcodeForm
        zipcode={zipcode}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
        ctaLabel="Update"
      />
      <div
        className={`flex flex-col gap-2 overflow-y-auto p-4 ${styles.customScrollbar}`}
      >
        {locations ? (
          <>
            {favoriteLocations.size === locations.length ? (
              <div className="w-full">
                <div className="bg-white p-8 rounded-lg shadow-md">
                  <h2 className="text-xl font-semibold mb-2 text-center">
                    No Locations Found Close to This Area
                  </h2>
                </div>
              </div>
            ) : (
              <></>
            )}
            {sortByDistance(locations).map((location) => (
              <LocationCard
                key={location.id}
                location={location}
                minimized
                onCardClick={() => {}}
              />
            ))}
          </>
        ) : (
          <NoLocationsFound />
        )}
      </div>
    </>
  )
}

export default UpdateWarehouse
