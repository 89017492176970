import React, { JSX, useEffect } from 'react'
import { useNavigation } from '../../../contexts/NavigationContext'
import { SidebarType } from '../../../contexts/NavigationContext/types'
import Menu from '@components/MainLayout/Sidebar/Menu'
import UpdateLocation from '@components/MainLayout/Sidebar/UpdateLocation'
import UpdateWarehouse from '@components/MainLayout/Sidebar/UpdateWarehouse'

const Contents: {
  [key in Exclude<SidebarType, null>]: () => JSX.Element
} = {
  Menu,
  UpdateLocation,
  UpdateWarehouse,
}

export const Sidebar = () => {
  const { sidebar, showSidebar } = useNavigation()

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showSidebar])

  const Content = sidebar ? Contents[sidebar] : () => <></>
  return (
    <div
      className={`bg-white top-0 left-0 text-bidfta-blue-dark fixed h-screen overflow-auto z-50 ease-in-out duration-500 md:w-3xl w-screen sm:w-96`}
      style={{
        transform: `translate(${showSidebar ? 0 : -100}%)`,
      }}
    >
      <Content />
    </div>
  )
}

export default Sidebar
