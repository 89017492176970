import React, { FC } from 'react'
import Link from 'next/link'
import classNames from 'classnames'

export type LinkItem = {
  label: string
  route?: string
  items?: LinkItem[]
  isSpecial?: boolean
}

type PropTypes = LinkItem

const LinkList: FC<PropTypes> = ({ label, items }) => {
  return (
    <div className="mb-8">
      <h2 className="font-bold text-3xl mb-4">{label}</h2>
      {items ? (
        <ul>
          {items.map(({ label, route, isSpecial }, i) =>
            label ? (
              route ? (
                <li key={label} className="my-1">
                  <Link
                    href={route}
                    className={classNames(
                      'hover:underline hover:text-bidfta-yellow-legacy',
                      isSpecial ? 'text-bidfta-yellow-legacy font-semibold' : ''
                    )}
                  >
                    {label}
                  </Link>
                </li>
              ) : (
                <li key={label} className="my-1">
                  {label}
                </li>
              )
            ) : (
              <br key={`${label}-${i}`} />
            )
          )}
        </ul>
      ) : (
        <></>
      )}
    </div>
  )
}

export default LinkList
