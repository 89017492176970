import { useRouter } from 'next/router'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'
import {
  ClearQueryType,
  ItemQuery,
  UpdateQueryType,
} from '@components/Items/types'
import { QUERY_KEYS } from '@components/Items/utils/functions'
import { useEffect, useState } from 'react'
import { deleteCookie, setCookie } from 'cookies-next'
import { usePathname } from 'next/navigation'

type UseQueryType = () => [
  ItemQuery,
  { updateQuery: UpdateQueryType; clearQuery: ClearQueryType }
]

const getQueries = (query: ItemQuery) => {
  const { state, city, slug, ...newQuery } = query

  return newQuery
}

const useQuery: UseQueryType = () => {
  const router = useRouter()
  const path = usePathname()
  const pathname = path?.includes('/location/') ? path : '/items'
  const { query: routerQuery } = router
  const isOnItemsPage = router.pathname === '/items'
  const isAuctionPage = router.pathname === '/category/[slug]/[pageId]'
  const isPastPage = router.pathname === '/past-auctions/[slug]/[pageId]'
  const isMnr = router.pathname === '/mnr-auctions/[slug]/[pageId]'
  const [query, setQuery] = useState(getQueries(router.query))

  useEffect(() => {
    setQuery(getQueries(router.query))
  }, [JSON.stringify(router.query)])

  useEffect(() => {
    if (!(isOnItemsPage || isAuctionPage || isPastPage || isMnr)) return
    const key = 'locationQuery'
    const value = routerQuery[isOnItemsPage ? 'locations' : 'location']
    const opts = { path: '/' }

    if (!_isBoolean(value) && _isEmpty(value)) {
      deleteCookie(key, opts)
    } else {
      setCookie(key, value, opts)
    }
  }, [query])

  const updateQuery: UpdateQueryType = async (
    key,
    value,
    canHaveMultipleValues = false
  ) => {
    const newQuery = { ...query }

    // DELETE QUERY IF UNDEFINED
    if (value === undefined || value === '') {
      delete newQuery[key]
    } else {
      const newValue = _isArray(value)
        ? value.map((id) => String(id))
        : String(value)

      if (newQuery[key] && canHaveMultipleValues) {
        // IF QUERY EXISTS
        if (_isArray(newQuery[key])) {
          // IF QUERY IS ARRAY
          if (_isArray(value)) {
            // IF NEW VALUE IS ARRAY
            if (
              // @ts-ignore
              newQuery[key]?.some((oldQuery: string) =>
                newValue.includes(oldQuery)
              )
            ) {
              // IF ARRAY HAS VALUE DELETE
              const oldQuery = newQuery[key] as string[]
              newQuery[key] = oldQuery?.filter(
                (val: string) => !newValue.includes(val)
              )
            } else {
              // IF ARRAY DOES NOT HAVE VALUE ADD
              // @ts-ignore
              newQuery[key] = newQuery[key]?.concat(value)
            }
          } else {
            // IF VALUE IS NOT ARRAY
            // @ts-ignore
            if (newQuery[key]?.includes(newValue)) {
              // IF ARRAY HAS VALUE DELETE
              const oldQuery = newQuery[key] as string[]
              newQuery[key] = oldQuery?.filter(
                (val: string) => val !== newValue
              )
            } else {
              // IF ARRAY DOES NOT HAVE VALUE ADD
              // @ts-ignore
              newQuery[key].push(newValue)
            }
          }
        } else {
          // IF QUERY IS STRING

          if (_isArray(value)) {
            // IF VALUE IS ARRAY

            // @ts-ignore
            if (value.includes(newQuery[key])) {
              // PREVIOUS VALUE EXIST DELETE
              delete newQuery[key]
            } else {
              // PREVIOUS VALUE DOES NOT EXIST CREATE
              // @ts-ignore
              newQuery[key] = [newQuery[key], ...newValue]
            }
          } else {
            if (newQuery[key] === newValue) {
              // PREVIOUS VALUE EXIST DELETE
              delete newQuery[key]
            } else {
              // PREVIOUS VALUE DOES NOT EXIST CREATE
              // @ts-ignore
              newQuery[key] = [newQuery[key], newValue]
            }
          }
        }
      } else {
        // IF QUERY DOES NOT EXIST
        newQuery[key] = newValue
      }
    }
    const queryObj = { pageId: 1, ...newQuery } as any

    // Remove empty or undefined values from the query object
    Object.keys(queryObj).forEach((queryKey) => {
      if (queryObj[queryKey] === '' || queryObj[queryKey] === undefined) {
        delete queryObj[queryKey]
      }
    })

    if (key !== QUERY_KEYS.PAGE_ID) {
      queryObj.pageId = '1'
    }

    await router.push({ pathname, query: queryObj }, undefined, {
      shallow: true,
    })
  }

  const clearQuery: ClearQueryType = async () => {
    await router.push(
      {
        pathname,
        query: {
          pageId: 1,
          [QUERY_KEYS.ITEM_SEARCH_KEYWORDS]:
            query[QUERY_KEYS.ITEM_SEARCH_KEYWORDS],
        },
      },
      undefined,
      {
        shallow: true,
      }
    )
  }

  return [query, { updateQuery, clearQuery }]
}

export default useQuery
