import HamburgerLibrary from 'hamburger-react'
import { useNavigation } from '../../../../contexts/NavigationContext'

const Hamburger = () => {
  const { showSidebar, toggleSidebar } = useNavigation()

  return (
    <HamburgerLibrary
      toggled={showSidebar}
      toggle={toggleSidebar('Menu')}
      color="#132c52"
    />
  )
}

export default Hamburger
