import { useEffect, useState } from 'react'
import OutsideClickAlerter from '@components/OutsideClickAlerter/OutsideClickAlerter'
import BidLocationFilter from '@components/MainLayout/Header/Filter/Location/BidLocationFilter/BidLocationFilter'
import _isArray from 'lodash/isArray'

const Location = ({
  isStatePage,
  locationGroupState,
  locations,
  addQuery,
  reduceQuery,
}: any) => {
  const [detailsOpen, setDetailsOpen] = useState<any>(false)
  const [displayValue, setDisplayValue] = useState<string>(
    getDisplayValue(formatLocationsToArray(locations), locationGroupState)
  )

  useEffect(() => {
    setDisplayValue(
      getDisplayValue(formatLocationsToArray(locations), locationGroupState)
    )
  }, [locations, locationGroupState])

  return (
    <>
      {!isStatePage && (
        <OutsideClickAlerter callback={() => setDetailsOpen(false)}>
          <div className="relative">
            <div className={`w-full z-20`}>
              <div
                className="text-base leading-5 text-bidfta-gray-dark border border-bidfta-gray-field border
                                    focus:outline-none focus:border-bidfta-blue relative rounded-md bg-white cursor-default pl-4 py-2"
                onClick={() => setDetailsOpen((prevState: any) => !prevState)}
                onKeyDown={(e) =>
                  e.key === 'Enter' &&
                  setDetailsOpen((prevState: any) => !prevState)
                }
                role="listbox"
                tabIndex={0}
              >
                {displayValue}
                <span
                  className={`w-1.5 h-1.5 absolute top-3.5 right-4 transform -rotate-45 border-b-2 border-l-2 border-bidfta-gray-borderdark`}
                />
              </div>
              <div
                className={`${
                  detailsOpen ? '' : 'hidden'
                } absolute left-0 top-10 right-0`}
              >
                <BidLocationFilter
                  locationGroupState={locationGroupState}
                  isMobileDropdown
                  locations={locations}
                  addQuery={addQuery}
                  reduceQuery={reduceQuery}
                />
              </div>
            </div>
          </div>
        </OutsideClickAlerter>
      )}
    </>
  )
}

const formatLocationsToArray = (locations: any) =>
  locations ? (_isArray(locations) ? locations : [locations]) : []

const getDisplayValue = (
  locations: (string | number)[] = [],
  locationGroupState: any
) => {
  if (!locations.length) return 'Location'

  const statesSelected: string[] = []
  const statesPartiallySelected: string[] = []
  const citiesSelected: string[] = []

  Object.entries(locationGroupState).forEach(
    ([stateName, cities]: [string, any]) => {
      if (
        Object.keys(cities).every((cityId: string) =>
          locations.includes(cityId)
        )
      ) {
        statesSelected.push(stateName)
      }
      if (
        Object.keys(cities).some((cityId: string) =>
          locations.includes(cityId)
        ) &&
        !statesSelected.includes(stateName)
      ) {
        statesPartiallySelected.push(stateName)
      }

      Object.entries(cities).forEach(([cityId, cityName]: any) => {
        if (locations.includes(cityId)) {
          citiesSelected.push(cityName)
        }
      })
    }
  )

  if (statesPartiallySelected.length === 0 && statesSelected.length === 1)
    return statesSelected[0]
  if (citiesSelected.length === 1) return citiesSelected[0]
  if (statesPartiallySelected.length === 0 && statesSelected.length > 1)
    return `${statesSelected.length} States`
  return `${citiesSelected.length} Cities`
}

export default Location
