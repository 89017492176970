import React from 'react'
import NavLink from '@components/MainLayout/reusable/NavLink'
import BidLogo from '../../../../elements/BidLogo/BidLogo'
import Hamburger from 'components/MainLayout/reusable/Hamburger'
import Search from '@components/Items/components/search'
import MyAccount from 'components/MainLayout/Header/Main/MyAccount'
import Link from 'next/link'
import Dropdown from '@components/MainLayout/Header/Main/Dropdown'
import { NAVIGATION } from '../../../../contexts/NavigationContext/constants'

const Main = () => {
  const tabClass =
    'font-bold text-bidfta-blue-dark hover:bg-slate-200 ease-in-out transition duration-200 py-2 md:px-1 px-2 rounded-md hover:cursor-pointer'

  return (
    <nav className="main-container mobile-padding">
      <div className=" lg:flex justify-between gap-2 w-full hidden">
        <div className="flex items-center gap-2 lg:gap-4 xl:gap-6 ">
          <div className="flex items-center gap-4 lg:gap-8">
            <Hamburger />
            <div style={{ minWidth: '150px' }}>
              <NavLink link="/">
                <BidLogo width={180} />
              </NavLink>
            </div>
          </div>
          <ul className="lg:gap-1 xl:gap-2 md:flex items-center">
            {NAVIGATION.header.map(({ label, route, items, isSpecial }) =>
              items ? (
                <li key={label} className={tabClass}>
                  <Dropdown label={label} items={items} isSpecial={isSpecial} />
                </li>
              ) : (
                <li key={label}>
                  {route ? (
                    <Link href={route} className={tabClass}>
                      {label}
                    </Link>
                  ) : (
                    <p className={tabClass}>{label}</p>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="flex gap-2 lg:gap-2 xl:gap-4 items-center w-full max-w-xs lg:max-w-md xl:max-w-lg 2xl:max-w-xl">
          <div className="w-full">
            <Search titleView />
          </div>
          <MyAccount />
        </div>
      </div>
      <div className="lg:hidden pb-4">
        <div className="flex justify-between items-center">
          <Hamburger />
          <div style={{ minWidth: '150px' }}>
            <NavLink link="/">
              <BidLogo width={180} />
            </NavLink>
          </div>
          <MyAccount />
        </div>
        <Search titleView />
      </div>
    </nav>
  )
}

export default Main
