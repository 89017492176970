import Link from 'next/link'
import BidLogo from '../../../elements/BidLogo/BidLogo'

const Logo = ({ className }: { className?: string }) => {
  return (
    <h3>
      <Link href="/">
        <BidLogo
          width={400}
          height={120}
          primary={'#FFFFFF'}
          secondary={'#FECE62'}
          className={className}
        />
      </Link>
    </h3>
  )
}

export default Logo
