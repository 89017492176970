import { useEffect, useState } from 'react'
import { getCookie, deleteCookie, setCookie } from 'cookies-next'
import C from '@utilities/constants'
import _isEmpty from 'lodash/isEmpty'
import _isBoolean from 'lodash/isBoolean'
import _isEqual from 'lodash/isEqual'

export const getCookieValue = ({
  key,
  options = { path: '/' },
  defaultValue,
}: any) => {
  let cookie = getCookie(key, options)
  if (typeof cookie === 'boolean') return cookie
  try {
    if (cookie) {
      cookie = JSON.parse(cookie)
    }
  } catch (e) {
    cookie = null
    console.error(e)
  }
  if (!cookie || _isEmpty(cookie)) return defaultValue

  return cookie
}

const useCookieState = (key: string, initialValue?: any, options?: any) => {
  const getInitialValue = () => {
    // if we on the server just use an initial value
    if (typeof window === 'undefined') return initialValue

    // otherwise get initial cookie value from `document.cookies`
    return getCookieValue({
      key,
      options: options?.decodeOps,
      defaultValue: initialValue,
    })
  }

  // get initial state value on component mounts
  const [value, setValue] = useState(getInitialValue)

  const shouldSync = () =>
    getInitialValue() &&
    !_isEqual(getInitialValue(), value) &&
    (key !== 'userData' || !_isEqual(getInitialValue(), C.EMPTY.USER_DETAILS))

  useEffect(() => {
    if (shouldSync()) {
      setValue(getInitialValue())
    }
    const timeout = setTimeout(() => {
      if (shouldSync()) {
        setValue(getInitialValue())
      }
    }, 500)
    const oneSecondTimeout = setTimeout(() => {
      if (shouldSync()) {
        setValue(getInitialValue())
      }
    }, 1000)
    const threeSecondTimeout = setTimeout(() => {
      if (shouldSync()) {
        setValue(getInitialValue())
      }
    }, 3000)
    return () => {
      clearTimeout(timeout)
      clearTimeout(oneSecondTimeout)
      clearTimeout(threeSecondTimeout)
    }
  }, [getInitialValue()])

  // encode and save the new cookie value
  const setNextValue = (value: any, opts = { path: '/' }) => {
    if (!_isBoolean(value) && _isEmpty(value)) {
      deleteCookie(key, opts)
    } else {
      setCookie(key, value, opts)
    }
    setValue(value)
  }

  return [value, setNextValue]
}

export default useCookieState
