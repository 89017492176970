import { FiMapPin } from 'react-icons/fi'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { FC, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Status, { StatusTypes } from '../../../../lib/Graphics/Status'

export interface FormData {
  zipcode: string
}

interface PropTypes {
  zipcode: string
  onSubmit: SubmitHandler<FormData>
  isSubmitting?: boolean
  errorMessage?: string | null
  ctaLabel?: string
}

const ZipcodeForm: FC<PropTypes> = ({
  zipcode,
  onSubmit,
  isSubmitting = false,
  errorMessage,
  ctaLabel = 'Update',
}) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { zipcode },
    resolver: yupResolver(schema),
  })
  const [status, setStatus] = useState<StatusTypes>('inactive')

  useEffect(() => {
    if (isSubmitting) {
      if (status === 'loading') return
      return setStatus('loading')
    }
    if (status === 'inactive') return
    if (status === 'loading') {
      if (errorMessage) return setStatus('failed')
      return setStatus('success')
    }
    const timeout = setTimeout(() => {
      setStatus('inactive')
    }, 1000)

    return () => clearTimeout(timeout)
  }, [isSubmitting, errorMessage, status])

  useEffect(() => {
    reset({ zipcode })
  }, [zipcode])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 px-4 pt-6">
      <div className="flex items-center">
        <FiMapPin className="mr-2" />
        <label
          htmlFor="zipcode"
          className="block text-sm font-medium text-gray-700"
        >
          Zip Code
        </label>
      </div>
      <div className="flex gap-4 items-center">
        <Controller
          name="zipcode"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              id="zipcode"
              type="text"
              className={`mt-1 block w-full px-3 py-2 border ${
                errors.zipcode ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              placeholder="Enter Zip Code"
            />
          )}
        />
        <button
          type="submit"
          disabled={status !== 'inactive'}
          className={`flex justify-center px-8 py-2 text-white rounded-md shadow-sm hover:bg-blue-700 w-48 h-10 transition ease-in-out duration-250 ${
            status === 'inactive'
              ? 'bg-bidfta-blue-light'
              : status === 'success'
              ? 'bg-darkgreen'
              : 'bg-slate-300'
          }`}
        >
          <Status status={status}>{ctaLabel}</Status>
        </button>
      </div>

      {errors.zipcode && (
        <p className="mt-2 text-sm text-bidfta-red font-bold">
          {errors.zipcode.message}
        </p>
      )}
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </form>
  )
}

const schema = yup.object().shape({
  zipcode: yup
    .string()
    .required('Zipcode is required')
    .matches(/^\d{5}$/, 'Zipcode must be exactly 5 digits'),
})

export default ZipcodeForm
