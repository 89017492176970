/*
(required) options = [{label: 'please select an option', value: 'example value'}]
(required) theme = "blue", "blue-dark", "blue-light", "gray" etc... NOTE: Need to extend the applyTheme() function
if trying to render a color that isn't in it
(optional) placeholder = String to be shown as the label of the first <option> field (which is disabled)
(optional) label = String to be shown in label
(optional) size = default "default", expanded
(optional) required = default false, true

<BidSelect
    options={[
        { label: 'opt 1', value: 2 },
        { label: 'opt binge', value: 77 },
    ]}
    label="Billing Address"
    placeholder="Address"
    theme="blue"
    size="expanded"
    required={true}
/>
*/

export default function BidSelect({
  selectValue,
  setSelectValue,
  options,
  label,
  placeholder,
  theme,
  size,
  required,
  twcss,
}: any) {
  const applyTheme = () => {
    let focusColor = ''

    switch (theme) {
      case 'blue':
        focusColor = 'focus:border-bidfta-blue'
        break
      case 'blue-light':
        focusColor = 'focus:border-bidfta-blue-light'
        break
      case 'blue-dark':
        focusColor = 'focus:border-bidfta-blue-dark'
        break
      default:
        break
    }
    return focusColor
  }

  return (
    <label htmlFor={label} className={`block ${twcss || ''}`}>
      {label || ''}
      {required && label && <span className="text-red relative left-1">*</span>}
      <div className="relative">
        <select
          required={required}
          value={selectValue}
          name={label}
          onChange={(e: any) => setSelectValue(e.target.value)}
          className={`block relative w-full max-w-3xl leading-5 rounded-md border pl-4 pr-7 text-bidfta-gray-dark
                    appearance-none border-bidfta-gray-fieldborder focus:outline-none bg-white text-base ${applyTheme()}
                    ${size === 'expanded' ? 'py-4' : 'py-2'}
                    `}
        >
          {placeholder && (
            <option
              className="disabled:text-bidfta-gray-dark/40"
              defaultValue={placeholder}
              disabled
            >
              {placeholder}
            </option>
          )}
          {options.map((option: any) => (
            <option
              value={option.value}
              key={option.value}
              className="text-black"
            >
              {option.label}
            </option>
          ))}
        </select>
        <span
          className={`w-1.5 h-1.5 pointer-events-none absolute right-4 transform -rotate-45 border-b-2 border-l-2 border-bidfta-gray-borderdark ${
            size === 'expanded' ? 'top-6' : 'top-4'
          }`}
        />
      </div>
    </label>
  )
}
