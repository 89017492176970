import Link from 'next/link'

const NavLink = ({
  className = 'w-full',
  link,
  children,
  openInNewTab = false,
  ...props
}: any) => (
  <Link
    href={link}
    className={'inline-block w-full'}
    rel={'noreferrer'}
    target="_self"
    {...props}
  >
    <div className={className}>{children}</div>
  </Link>
)

export default NavLink
