import { useContext, useEffect, useState } from 'react'
import OutsideClickAlerter from '@components/OutsideClickAlerter/OutsideClickAlerter'
import BidPickupFilter from '@components/MainLayout/Header/Filter/PickUpDates/BidPickupFilter'
import getArrQuery from '../../../../../utilities/functions/getArrQuery'
import _cloneDeep from 'lodash/cloneDeep'
import { AuthContext } from '../../../../../contexts/AuthContext'

type Props = {
  pickupDates: string[]
  activePickupDates: string[]
  addQuery: any
  reduceQuery: any
  locations?: any[]
  category?: string
}

const PickUpDates = ({
  pickupDates,
  activePickupDates,
  addQuery,
  reduceQuery,
  locations,
  category,
}: Props) => {
  const { instance } = useContext<any>(AuthContext)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [availablePickupDates, setAvailablePickupDates] =
    useState<string[]>(pickupDates)

  const fetchData = async () => {
    let url = `/auction/getAuctionPickupDate`

    const categoryName =
      category === 'all'
        ? 'Categories - All'
        : _cloneDeep(category)?.replaceAll('-', '%20')
    const locationIds = getArrQuery(locations).reduce(
      (acc, curr, i, array) =>
        acc + `locationIds=${curr}${array.length !== i + 1 ? '&' : ''}`,
      ''
    )
    url = `${url}?categories=${categoryName}`
    if (locationIds) {
      url = url + '&' + locationIds
    }
    const { data }: { data: string[] } = await instance.get(url)
    setAvailablePickupDates(data)
  }

  useEffect(() => {
    fetchData()
  }, [category, locations])

  return (
    <OutsideClickAlerter callback={() => setIsDropdownOpen(false)}>
      <div className="relative block">
        <div
          className="text-base leading-5 text-bidfta-gray-dark border border-bidfta-gray-fieldborder
                                            focus:outline-none focus:border-bidfta-blue relative rounded-md
                                            bg-white cursor-default pl-4 py-2"
          onClick={() => setIsDropdownOpen((prevState: any) => !prevState)}
          onKeyDown={(e) =>
            e.key === 'Enter' &&
            setIsDropdownOpen((prevState: any) => !prevState)
          }
          role="listbox"
          tabIndex={0}
        >
          Pick Up Dates
          <span
            className="w-1.5 h-1.5 absolute top-3.5 right-4 transform -rotate-45
                                               border-b-2 border-l-2 border-bidfta-gray-borderdark"
          />
        </div>
        <ol
          className={`${
            isDropdownOpen ? '' : 'hidden'
          } absolute left-0 top-10 right-0 bg-white p-4 z-10 overflow-auto`}
          style={{ maxHeight: '60vh' }}
        >
          {availablePickupDates.map((pickupDate: any) => (
            <BidPickupFilter
              key={pickupDate}
              pickupDate={pickupDate}
              activePickupDates={activePickupDates}
              addQuery={addQuery}
              reduceQuery={reduceQuery}
            />
          ))}
        </ol>
      </div>
    </OutsideClickAlerter>
  )
}

export default PickUpDates
