import pascalCaseToSpaceSeparated from '@utilities/functions/pascalCaseToSpaceSeparated'
import Hamburger from '@components/MainLayout/reusable/Hamburger'

const Title = ({ children }: { children: string | null }) => {
  return (
    <div className="p-4 font-bold text-3xl border-2 border-b-bidfta-yellow-legacy flex justify-between items-center">
      <h2>{pascalCaseToSpaceSeparated(children)}</h2>
      <Hamburger />
    </div>
  )
}

export default Title
