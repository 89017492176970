import styles from '../BidLocationState.module.scss'
import getArrQuery from '../../../../../../../../utilities/functions/getArrQuery'

export default function BidLocationCity({
  locationId,
  locationName,
  locations,
  addQuery,
  reduceQuery,
}: any) {
  const isChecked = getArrQuery(locations).includes(locationId)

  const handleCheckClick = () => {
    if (isChecked) {
      reduceQuery({ key: 'location', value: locationId })
    } else {
      addQuery({ key: 'location', value: locationId })
    }
  }

  return (
    <li className="font-normal mb-2.5">
      <label htmlFor={locationName} className={`${styles.check__label}`}>
        <button
          onClick={handleCheckClick}
          className={'text-left flex flex-row items-center'}
        >
          <div
            className="checkbox cursor-pointer mr-4"
            role="button"
            onKeyDown={(e: any) => e.key === 'Enter' && handleCheckClick()}
            tabIndex={0}
          >
            {isChecked && (
              <svg
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                fill="#297AF7"
              >
                <g>
                  <polygon
                    className="st0"
                    points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "
                  />
                </g>
              </svg>
            )}
          </div>
          <div>
            <span
              id={locationName}
              className="inline-block text-left"
              style={{ textAlign: 'start' }}
            >
              {locationName}
            </span>
          </div>
        </button>
      </label>
    </li>
  )
}
