import { FaLocationDot } from 'react-icons/fa6'
import { MdWarehouse } from 'react-icons/md'
import { useNavigation } from '../../../../contexts/NavigationContext'
import { FC, useMemo } from 'react'

const UserPreferences = () => {
  const {
    toggleSidebar,
    zipcode,
    favoriteLocations,
    locationsInArea,
    allLocations,
  } = useNavigation()

  const visibleLocation = useMemo(() => {
    const noneFound = 'No Locations'

    const favLocCount = favoriteLocations.size
    if (favLocCount !== 0) {
      const aFavoriteId = Number(favoriteLocations.values().next().value)
      const location = allLocations.find(({ id }) => id === aFavoriteId)
      if (location)
        return <LocationsInArea count={favLocCount} name={location.nickName} />
    }

    const locInAreaCount = locationsInArea?.length || 0
    if (locationsInArea && locInAreaCount !== 0) {
      const nearbyId = Number(locationsInArea[0].id)
      const location = allLocations.find(({ id }) => id === nearbyId)
      if (location)
        return (
          <LocationsInArea count={locInAreaCount} name={location.nickName} />
        )
    }

    return <div>{noneFound}</div>
  }, [favoriteLocations, allLocations, locationsInArea])

  return (
    <button
      className="bg-gradient-to-r from-bidfta-blue-backdrop-dark to-[#246c84] main-container w-full"
      onClick={toggleSidebar('UpdateWarehouse')}
    >
      <div
        className="p-4 text-white flex gap-4 font-semibold"
        style={{ fontSize: '14px' }}
      >
        {!visibleLocation || !zipcode ? (
          <p className="font-bold font-raleway flex gap-1 items-center">
            <FaLocationDot color={`#fece62`} /> Where do you pick up?
          </p>
        ) : (
          <></>
        )}
        <div className="flex gap-2 items-center">
          <MdWarehouse />
          <div className="text-sm">{visibleLocation}</div>
        </div>
        <div className="flex gap-2 items-center">
          {visibleLocation && zipcode ? (
            <FaLocationDot color={`#fece62`} />
          ) : (
            <></>
          )}
          <div className="text-md">{zipcode}</div>
        </div>
      </div>
    </button>
  )
}

const LocationsInArea: FC<{ name: string; count: number }> = ({
  name,
  count,
}) => (
  <div className="flex gap-2">
    <div className="line-clamp-1">{name}</div>
    <div className="inline-block">+{count}</div>
  </div>
)

export default UserPreferences
