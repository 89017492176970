import { useState, useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOutsideClickAlerter(ref: any) {
  const [outsideClick, setOutsideClick] = useState<any>(false)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOutsideClick(true)
      } else {
        setOutsideClick(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleClickOutside)
    }
  }, [ref])

  return {
    outsideClick,
  }
}
