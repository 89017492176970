import _isArray from 'lodash/isArray'
import dateFormat from 'dateformat'
type buildQueriesType = (
  queries:
    | {
        itemSearchKeywords?: string
        msrpFrom?: string
        msrpTo?: string
        categoryJson?: { [category1: string]: string[] }
        locations?: number[] | string[]
        itemConditionIds?: number[] | string[]
        pickupDates?: string[]
        auctionEndDates?: string[]
        pageId?: number | string
      }
    | undefined
) => string

export const buildQueries: buildQueriesType = (queries) => {
  if (!queries) return ''

  return Object.values(QUERY_KEYS).reduce((query, queryKey) => {
    const value = queries[queryKey]

    if (!value) return query
    return `${query}${query.includes('?') ? '&' : '?'}${
      queryKey === QUERY_KEYS.END_DATE || queryKey === QUERY_KEYS.PICKUP_DATES
        ? _isArray(value)
          ? value
              .map(
                (queryVal) =>
                  `${queryKey}=${reformatStringToURLQuery(String(queryVal))}`
              )
              .join('&')
          : `${queryKey}=${reformatStringToURLQuery(String(value))}`
        : `${queryKey}=${_isArray(value) ? combineArray(value) : value}`
    }`
  }, '')
}

const reformatStringToURLQuery = (string: string) => {
  // Define an object to map special characters to their encoded values
  const specialCharacters: { [key: string]: string } = {
    ' ': '+',
    '!': '%21',
    '#': '%23',
    $: '%24',
    '%': '%25',
    '&': '%26',
    "'": '%27',
    '(': '%28',
    ')': '%29',
    '*': '%2A',
    '+': '%2B',
    ',': '%2C',
    '/': '%2F',
    ':': '%3A',
    ';': '%3B',
    '=': '%3D',
    '?': '%3F',
    '@': '%40',
    '[': '%5B',
    ']': '%5D',
  } as const

  // Remove leading and trailing white spaces
  string = string.trim()

  // Replace special characters with their encoded values
  let encodedString = ''
  for (let i = 0; i < string.length; i++) {
    const char = string[i]
    if (specialCharacters.hasOwnProperty(char)) {
      encodedString += specialCharacters[char]
    } else {
      encodedString += char
    }
  }

  return encodedString
}

const combineArray = (arr: (string | number)[] = []) => {
  if (!arr.length) return ''
  return `${arr.map((val) => reformatStringToURLQuery(String(val))).join(',')}`
}

export const splitActiveIds = (
  activeIds: Array<number | string>,
  availableIds: Array<number | string>
) => {
  const setAvailableIds = new Set(activeIds)
  const checked: Array<string> = []
  const unchecked: Array<string> = []

  for (let i = 0; i < availableIds.length; i++) {
    if (setAvailableIds.has(availableIds[i])) {
      checked.push(String(availableIds[i]))
    } else {
      unchecked.push(String(availableIds[i]))
    }
  }

  return { checked, unchecked }
}

export const formatPickupDate = (date: string = '') => {
  const formattedDate = date.replace(/\b(\d{1,2})(st|nd|rd|th)\b/, '$1')
  const parsedDate = Date.parse(formattedDate)

  return dateFormat(parsedDate, '(ddd) mmmm d, yyyy')
}

export const QUERY_KEYS = {
  ITEM_SEARCH_KEYWORDS: 'itemSearchKeywords',
  MSRP_FROM: 'msrpFrom',
  MSRP_TO: 'msrpTo',
  CATEGORIES: 'categoryJson',
  LOCATIONS: 'locations',
  ITEM_CONDITION_IDS: 'itemConditionIds',
  PICKUP_DATES: 'pickupDates',
  END_DATE: 'auctionEndDates',
  PAGE_ID: 'pageId',
} as const
