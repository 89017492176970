const Contact = () => {
  return (
    <div className={'flex flex-col'}>
      <h3 className={'text-bidfta-yellow-legacy font-semibold'}>
        CONTACT BIDFTA
      </h3>
      <p className={'hover:text-bidfta-yellow-legacy'}>
        <a href="mailto:support@bidfta.com">support@bidfta.com</a>
      </p>
      <p className={'hover:text-bidfta-yellow-legacy'}>
        <a href="tel:+513-771-1700">(513) 771 1700</a>
      </p>
    </div>
  )
}

export default Contact
