import Button from '../../../../elements/Button'
import BackgroundLayout from '../../../../elements/BackgroundLayout'
import Categories from '@components/MainLayout/Header/Filter/Categories'
import PickUpDates from '@components/MainLayout/Header/Filter/PickUpDates'
import Location from '@components/MainLayout/Header/Filter/Location'
import useAuctionRoute from '../../../../utilities/hooks/useAuctionRoute'
import useIsDesktop from '@utilities/hooks/useIsDesktop'

const Filter = ({
  locationGroupState,
  isStatePage,
  categories,
  pickupDates,
}: any) => {
  const {
    query: { queries, addQuery, reduceQuery },
    route: { base, clear, category },
  } = useAuctionRoute()
  const isDesktop = useIsDesktop()

  return (
    <BackgroundLayout height={`${isDesktop ? 6 : 7}rem`} width={'full'}>
      <div
        className={'grid grid-cols-2 md:grid-cols-7 h-full items-start md:pt-4'}
      >
        <ItemLayout>
          <Location
            isStatePage={isStatePage}
            locationGroupState={locationGroupState}
            locations={queries.location}
            addQuery={addQuery}
            reduceQuery={reduceQuery}
          />
        </ItemLayout>
        <ItemLayout>
          <Categories categories={categories} />
        </ItemLayout>
        {base === 'mnr-auctions' ? (
          <></>
        ) : (
          <ItemLayout>
            <PickUpDates
              category={category}
              locations={queries.location}
              pickupDates={pickupDates}
              activePickupDates={queries.pickupDate}
              addQuery={addQuery}
              reduceQuery={reduceQuery}
            />
          </ItemLayout>
        )}
        <ItemLayout className={'col-span-1'}>
          <Button onClick={() => clear()}>Clear Filters</Button>
        </ItemLayout>
      </div>
    </BackgroundLayout>
  )
}

const ItemLayout = ({
  className = 'col-span-1 md:col-span-2',
  children,
}: any) => <div className={`px-2 ${className}`}>{children}</div>

export default Filter
