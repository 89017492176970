import BidSelect from '../../../../../elements/BidSelect/BidSelect'
import useAuctionRoute from '../../../../../utilities/hooks/useAuctionRoute'
import useGtmAuctionCategoryEvent from '../../../../../utilities/hooks/useGtmAuctionCategoryEvent'

const getSlug = (path: string) => path.replace(/^\/|\/$/g, '')

const Categories = ({ categories }: { categories: any[] }) => {
  const {
    route: { category, changeCategory },
  } = useAuctionRoute()
  useGtmAuctionCategoryEvent(category)
  const options = categories.map((cat: any) => ({
    label: cat.name,
    value:
      cat.name === 'Categories - All'
        ? 'all'
        : cat.name.replace(/\s+/g, '-').toLowerCase(),
  }))

  return (
    <BidSelect
      selectValue={category}
      setSelectValue={(val: any) => {
        changeCategory(getSlug(val))
      }}
      options={options}
      theme="blue"
    />
  )
}

export default Categories
