import Filter from '@components/MainLayout/Header/Filter'
import UserPreferences from '@components/MainLayout/Header/UserPreferences'
import Main from '@components/MainLayout/Header/Main'
import classNames from 'classnames'
import { useRouter } from 'next/router'

const Header = ({
  showFilter = false,
  locationGroupState,
  isStatePage,
  categories,
  pickupDates,
}: any) => {
  const { asPath, pathname } = useRouter()

  return (
    <>
      <header
        className={`w-full print:hidden bg-white fixed z-50 border-b-4 border-slate-200 ease-in-out duration-200`}
      >
        <UserPreferences />
        <Main />
      </header>
      <div
        className={classNames(
          'duration-200 ease-in-out',
          'h-[12rem] lg:h-[9rem]',
          asPath === '/' ? '' : 'bg-[#f5f5f5]'
        )}
      />
      {showFilter && (
        <div className={`main-container`}>
          <Filter
            locationGroupState={locationGroupState}
            isStatePage={isStatePage}
            categories={categories}
            pickupDates={pickupDates}
          />
        </div>
      )}
    </>
  )
}

export default Header
