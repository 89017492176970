import { useState, useMemo, useCallback } from 'react'
//import getArrQuery from 'utilities/functions/getArrQuery'
import getArrQuery from '@utilities/functions/getArrQuery'
import BidLocationCity from './BidLocationCity/BidLocationCity'
import styles from './BidLocationState.module.scss'

export default function BidLocationState({
  location,
  listName,
  locations,
  addQuery,
  reduceQuery,
}: any) {
  const [open, setOpen] = useState<any>(false)

  const isChecked = Object.keys(location).every((locationId) =>
    getArrQuery(locations).includes(locationId)
  )

  const sortLocations = useCallback(
    (a: any, b: any) => (a[1] > b[1] ? 1 : -1),
    []
  )
  const sortedLocations = useMemo(
    () => Object.entries(location).sort(sortLocations),
    []
  )

  return (
    <li>
      <div className="relative">
        <div className="text-left flex flex-row ">
          <div
            className={`checkbox cursor-pointer mr-4 ${styles.state_checkbox}`}
            role="button"
            onClick={() => {
              if (isChecked) {
                reduceQuery({ key: 'location', value: Object.keys(location) })
              } else {
                addQuery({ key: 'location', value: Object.keys(location) })
              }
            }}
            tabIndex={0}
          >
            {isChecked && (
              <svg
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                fill="#297AF7"
              >
                <g>
                  <polygon
                    className="st0"
                    points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "
                  />
                </g>
              </svg>
            )}
          </div>
          <div
            onClick={() => setOpen(!open)}
            onKeyDown={(e: any) => e.key === 'Enter' && setOpen(!open)}
            className="w-full"
          >
            <div
              key={listName}
              role="button"
              tabIndex={0}
              className={`mb-2.5 relative ${open ? 'font-bold' : 'font-normal'
                }`}
            >
              <div>{listName}</div>
            </div>

            <div
              className={`w-2.5 h-2.5 bg-transparent absolute right-0 top-1 ${styles.arrow_block
                } ${open ? styles.open : ''}`}
            >
              <span className={styles.arrow} />
            </div>
          </div>
        </div>
      </div>

      <ul
        className={
          open
            ? `${styles.location_sub_list}`
            : `${styles.location_sub_list} hidden`
        }
      >
        {sortedLocations.map((item: any) => (
          <BidLocationCity
            locationId={item[0]}
            locationName={item[1]}
            key={item[0]}
            locations={locations}
            addQuery={addQuery}
            reduceQuery={reduceQuery}
          />
        ))}
      </ul>
    </li>
  )
}
