import React from 'react'

const NoLocationsFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-2 text-center">
          No Auction Locations Found in the Area
        </h2>
      </div>
    </div>
  )
}

export default NoLocationsFound
