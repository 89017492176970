import { useState, useEffect } from 'react'
import useQuery from '@components/Items/utils/hooks/useQuery'
import { useRouter } from 'next/router'
import { QUERY_KEYS } from '@components/Items/utils/functions'
import { ItemQuery } from '@components/Items/types'
import { getCookieValue } from '@utilities/hooks/useCookieState'

const useSearch = (): [string, (value: string) => void] => {
  const router = useRouter()
  const [query] = useQuery()
  const [searchValue, setSearchValue] = useState(
    query.itemSearchKeywords || (query.searchInput as string) || ''
  )

  const isOnItemsPage = router.pathname === '/items'
  const isOnCategoryPage = router.pathname === '/category/[slug]/[pageId]'

  const handleSearchChange: (value: string) => void = async (value) => {
    value = value?.replace('|', '') // api error workaround BID2021-2360
    setSearchValue(value)
    if (/^[A-Za-z]{3}\d{7}$/.test(value)) {
      return router.push(`/api/auctionId/${value}`)
    }

    if ((isOnItemsPage || !!value) && value !== query.itemSearchKeywords) {
      const newQuery: ItemQuery = {
        pageId: '1',
        itemSearchKeywords: value,
      }

      if (isOnItemsPage)
        return router.push(
          {
            pathname: '/items',
            query: { ...query, ...newQuery },
          },
          undefined,
          { shallow: true }
        )

      if (!isOnItemsPage) {
        const locationQuery = getCookieValue({
          key: 'locationQuery',
          options: { path: '/' },
          defaultValue: undefined,
        })
        if (locationQuery) {
          // @ts-ignore
          newQuery[QUERY_KEYS.LOCATIONS] = locationQuery
        }
        if (query.pickupDate?.length) {
          newQuery[QUERY_KEYS.PICKUP_DATES] = Array.isArray(query.pickupDate)
            ? query.pickupDate
            : [query.pickupDate]
        }
      }

      if (isOnCategoryPage && router.query.location) {
        // @ts-ignore
        newQuery[QUERY_KEYS.LOCATIONS] = router.query.location
      }

      return router.push({
        pathname: '/items',
        query: newQuery,
      })
    }
  }

  useEffect(() => {
    setSearchValue(query?.itemSearchKeywords || '')
  }, [query?.itemSearchKeywords])

  return [searchValue, handleSearchChange]
}

export default useSearch
