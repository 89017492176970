import { FC } from 'react'
import { Location, OnCardClickType } from '@components/Location/location.types'
import Link from 'next/link'
import Image from 'next/image'
import Favorite from '@components/Location/reusable/LocationCard/Favorite'
import { useNavigation } from '../../../../contexts/NavigationContext'

type Props = {
  location: Location
  onCardClick: OnCardClickType
  minimized?: boolean
}

const Miles: FC<{ children?: string | undefined | null }> = ({ children }) => {
  if (!children) return <></>
  return (
    <p className="text-sm md:text-md text-right">
      <span className="font-bold ">{children} miles</span> from location
    </p>
  )
}
const LocationCard: FC<Props> = (props) => {
  const { setShowSidebar } = useNavigation()
  const {
    location: {
      id,
      slug,
      nickName,
      address,
      city,
      state,
      zip,
      hours,
      extendedHours,
      lat,
      lng,
      miles,
      isFranchise,
    },
    onCardClick,
    minimized = false,
  } = props

  return (
    <button
      key={id}
      id={`location-card-${id}`}
      data-testid={`location-card-${id}`}
      onClick={() => onCardClick({ id, lat, lng })}
      className="rounded shadow-lg hover:shadow-2xl transform duration-300 ease-in-out relative bg-white text-left"
    >
      <div className="flex-col px-4 py-2 gap-4 md:px-6 md:pt-8 md:pb-6 flex md:gap-4">
        <div>
          <div className="flex justify-between md:mb-1">
            <h4 className="font-bold text-md md:text-lg ">{nickName}</h4>
            <Favorite locationId={id} />
          </div>
          <p className="text-gray-700 text-xs font-bold">
            {address} {city}, {state} {zip}
          </p>
        </div>
        {isFranchise ? (
          <div>
            <div className="flex justify-between md:mb-1">
              <h5 className="font-bold text-sm md:text-md ">Affiliate</h5>
            </div>
            <p className="text-gray-700 text-xs font-bold">
              Independently owned and operated
            </p>
          </div>
        ) : (
          <></>
        )}
        {minimized ? (
          <div className="flex gap-8 items-center">
            <Miles>{miles}</Miles>
            <Link
              href={`/location/${state}/${city}/${slug}`}
              className={
                'text-bidfta-blue-light font-bold hover:underline text-11 md:text-xs'
              }
              onClick={(e) => {
                e.stopPropagation()
                setShowSidebar(false)
              }}
            >
              SEE MORE
            </Link>
          </div>
        ) : (
          <>
            <div className="flex flex-row gap-2 md:gap-8 text-12 md:text-sm">
              <div>
                <h5 className="font-bold ">PICK UP HOURS</h5>
                <p style={{ fontSize: 12 }}>{hours}</p>
              </div>
              {extendedHours && (
                <div className="w-full">
                  <h5 className="font-bold inline-flex items-center">
                    BIDFTA+ EARLY PICK UP
                    <Image
                      src="/bidfta_plus_logo.svg"
                      alt="BIDFTA+ Logo"
                      width={25}
                      height={25}
                      className="ml-2"
                    />
                  </h5>
                  <p style={{ fontSize: 12 }}>{extendedHours}</p>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-2 justify-between">
              <Link
                href={`/location/${state}/${city}/${slug}`}
                className={
                  'text-bidfta-blue-light font-bold hover:underline text-11 md:text-xs'
                }
                onClick={(e) => e.stopPropagation()}
              >
                LOCATION DETAILS
              </Link>
              <Link
                href={`/category/all/1?location=${id}`}
                className={
                  'text-bidfta-blue-light font-bold hover:underline text-11 md:text-xs'
                }
                onClick={(e) => e.stopPropagation()}
              >
                SEE AUCTIONS
              </Link>
              <Miles>{miles}</Miles>
            </div>
          </>
        )}{' '}
      </div>
    </button>
  )
}

export default LocationCard
