import { FC, useState } from 'react'
import NavLink from '@components/MainLayout/reusable/NavLink'
import Link from 'next/link'

const Dropdown = ({
  route,
  openInNewTab,
  label,
  items = [],
  isSpecial,
}: {
  route?: string
  label: string
  openInNewTab?: boolean
  items?: { route: string; label: string; openInNewTab?: boolean }[]
  isSpecial?: boolean
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const hasSubItems = items.length

  return (
    <div
      className={'flex flex-col'}
      onMouseLeave={() => setIsOpen(false)}
      onMouseEnter={() => {
        if (!hasSubItems) return
        setIsOpen(true)
      }}
    >
      <div className={'flex flex-row items-end text-bidfta-blue-dark'}>
        {route ? (
          <NavLink link={route} openInNewTab={openInNewTab}>
            <h2 className="font-bold">{label}</h2>
          </NavLink>
        ) : (
          <button className="font-bold">{label}</button>
        )}
        {hasSubItems ? (
          <DropdownButton onClick={() => setIsOpen(true)} />
        ) : (
          <></>
        )}
      </div>
      {isOpen ? (
        <div
          className={
            'absolute z-60 mt-5 py-2 rounded border-2 border-bidfta-blue-backdrop-dark bg-white'
          }
        >
          {isSpecial ? (
            <Special items={items} />
          ) : (
            items.map(({ route, label, openInNewTab }, i) => (
              <div key={`dropdown-item-${label}-${i}`}>
                <NavLink link={route} openInNewTab={openInNewTab}>
                  <div className={'bg-white'}>
                    <h3
                      className={
                        'text-bidfta-blue-backdrop-dark font-bold hover:bg-gray-200 p-2'
                      }
                    >
                      {label}
                    </h3>
                  </div>
                </NavLink>
              </div>
            ))
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

type Item = {
  route: string
  label: string
  openInNewTab?: boolean
  isSpecial?: boolean
}

const Special: FC<{ items?: Item[] }> = ({ items = [] }) => {
  const itemsPerColumn = 12
  const { specialItems, regularItems } = separateItems(items)

  const columns: Item[][] = regularItems.reduce<Item[][]>((acc, curr, i) => {
    const index = Math.floor(i / itemsPerColumn)

    if (acc[index]) {
      acc[index].push(curr)
    } else {
      acc[index] = [curr]
    }

    return acc
  }, [])

  const [firstSpecialItem, ...restOfSpecialItems] = specialItems

  return (
    <div className="px-2">
      <SpecialItem {...firstSpecialItem} />
      <div className={`grid grid-cols-${columns.length} gap-2`}>
        {columns.map((items, key) => (
          <div key={`column-${key}`}>
            {items.map(({ label, route }, key2) => (
              <Link key={`label-${key}-${key2}`} href={route}>
                <div className="w-full text-bidfta-blue-backdrop-dark font-bold hover:bg-gray-200 px-2 py-1">
                  {label}
                </div>
              </Link>
            ))}
          </div>
        ))}
        <div></div>
      </div>
      <div className="flex gap-4">
        {restOfSpecialItems.map((item, i) => (
          <SpecialItem {...item} key={`special-item-${i}`} />
        ))}
      </div>
    </div>
  )
}

const SpecialItem: FC<Item> = ({ label, route }) => (
  <Link href={route}>
    <div className="px-2 py-1 text-[#3d7cb7] text-lg font-bold hover:underline">
      {label}
    </div>
  </Link>
)

export const DropdownButton = ({ onClick, onHover = true }: any) => (
  <button
    type="button"
    className="text-sm font-medium"
    onClick={onClick}
    onMouseEnter={onHover ? onClick : () => {}}
  >
    <svg
      className="h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </button>
)

const separateItems = (
  items: Item[]
): { specialItems: Item[]; regularItems: Item[] } => {
  const specialItems: Item[] = []
  const regularItems: Item[] = []

  items.forEach((item) => {
    if (item?.isSpecial) {
      specialItems.push(item)
    } else {
      regularItems.push(item)
    }
  })

  return { specialItems, regularItems }
}

export default Dropdown
