import { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import NavLink from '@components/MainLayout/reusable/NavLink'
import { MdAccountCircle } from 'react-icons/md'
import { AuthContext } from '../../../../../contexts/AuthContext'
import BidButton from '../../../../../elements/BidButton/BidButton'
import { useRouter } from 'next/router'
import useIsDesktop from '@utilities/hooks/useIsDesktop'
import OutsideClickAlerter from '@components/OutsideClickAlerter/OutsideClickAlerter'
import { GlobalUIContext } from '../../../../../contexts/GlobalUIContext'
import LoadingSpinner from '../../../../../elements/LoadingSpinner'
import Link from 'next/link'

const MyAccount = () => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [show, setShow] = useState('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const { userData, isAuthenticated, isLoading, refreshAccessToken } =
    useContext<any>(AuthContext)
  const isDesktop = useIsDesktop()

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const open = useCallback(
    () => () => {
      setIsOpen(true)
    },
    [setIsOpen]
  )

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!isAuthenticated) {
      setShow('login')
    } else {
      setShow('MyAccount')
    }
  }, [isAuthenticated, isDesktop, isLoading])

  const handleLogin = useCallback(async () => {
    // Set sessionStorage item for post-login use
    sessionStorage.setItem('pageMarker', router.asPath)

    await router.push('/account/login')
  }, [router, refreshAccessToken])

  const {
    profile: { id },
  } = useMemo(() => userData, [userData])

  if (isLoading) return <LoadingSpinner />

  if (isDesktop && 'login' === show)
    return (
      <div
        className={
          'flex flex-col w-24 items-end justify-items-center transition ease-in-out duration-200'
        }
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
      >
        <BidButton
          label="Log In"
          theme="blue-light"
          callBack={handleLogin}
          width="full"
          buttonClassName="whitespace-nowrap"
        />
        {showDropdown ? (
          <div className="relative justify-end flex z-60">
            <div className="absolute bg-white border-2 border-bidfta-blue-dark rounded-md flex flex-col p-4 gap-4">
              <div className="w-64">
                <BidButton
                  label="Log In"
                  theme="blue-light"
                  callBack={handleLogin}
                  width="full"
                  buttonClassName="whitespace-nowrap"
                />
              </div>
              <Link
                href="/account/register/bidder"
                className="underline relative z-30 text-center text-sm font-medium text-teal-500 hover:text-teal-700"
              >
                Create an Account
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    )

  if ('' === show) return <></>

  if (!isAuthenticated && !isDesktop)
    return (
      <div className={'flex flex-col w-16'}>
        <BidButton
          label="Log In"
          theme="blue-light"
          callBack={() => setShowDropdown(true)}
          width="full"
          buttonClassName="whitespace-nowrap"
        />
        {showDropdown ? (
          <OutsideClickAlerter
            className="relative justify-end flex z-60"
            callback={() => setShowDropdown(false)}
          >
            <div className="absolute bg-white border-2 border-bidfta-blue-dark rounded-md flex flex-col p-4 gap-4">
              <div className="w-64">
                <BidButton
                  label="Log In"
                  theme="blue-light"
                  callBack={handleLogin}
                  width="full"
                  buttonClassName="whitespace-nowrap"
                />
              </div>
              <Link
                href="/account/register/bidder"
                className="underline relative z-30 text-center text-sm font-medium text-teal-500 hover:text-teal-700"
              >
                Create an Account
              </Link>
            </div>
          </OutsideClickAlerter>
        ) : (
          <></>
        )}
      </div>
    )

  return (
    <>
      <OutsideClickAlerter callback={close}>
        <div
          className="flex flex-col items-end"
          onMouseLeave={close}
          onMouseEnter={isDesktop ? open : () => {}}
        >
          <button
            className="flex flex-row font-bold text-bidfta-blue-dark hover:text-bidfta-blue-light items-center"
            onClick={toggle}
          >
            <MdAccountCircle size="3em" />
          </button>

          {isOpen ? <DropDownContent id={id} /> : <></>}
        </div>
      </OutsideClickAlerter>
    </>
  )
}
const DropDownContent = ({ id }: any) => {
  const { setShowLogout } = useContext<any>(GlobalUIContext)
  return (
    <div
      className={
        'fixed w-64 text-white z-60 mt-8 py-2 rounded border-2 border-bidfta-blue-backdrop-dark bg-white overflow-auto'
      }
      style={{ maxHeight: '80vh' }}
    >
      <div className={'bg-white'}>
        <h3 className={'text-bidfta-blue-backdrop-dark font-bold p-2'}>
          Welcome, Bidder #{id}
        </h3>
      </div>
      <span
        onClick={() => setShowLogout(true)}
        className={'w-full cursor-pointer'}
      >
        <div className={'w-full hover:bg-gray-200'}>
          <h3 className={'text-bidfta-blue-backdrop-dark font-bold p-2 w-full'}>
            Log Out
          </h3>
        </div>
      </span>
      <NavLink
        link={'/account/dashboard'}
        className={'w-full hover:bg-gray-200'}
      >
        <h3 className={'text-bidfta-blue-backdrop-dark font-bold p-2 w-full'}>
          Dashboard
        </h3>
      </NavLink>

      {LINKS.map(({ label, route }, i) => (
        <NavLink
          link={route}
          className={'w-full hover:bg-gray-200 pl-4'}
          key={`dashboard-link-${label}-${i}`}
        >
          <h4 className={'text-bidfta-blue-backdrop-dark font-bold p-2 w-full'}>
            {label}
          </h4>
        </NavLink>
      ))}
    </div>
  )
}

type showType = 'auction' | 'all'
type linkType = { label: string; route: string; show: showType }

const LINKS: linkType[] = [
  { label: 'Bids', route: '/account/dashboard/bids/1', show: 'auction' },
  {
    label: 'Watch List',
    route: '/account/dashboard/watchlist/1',
    show: 'auction',
  },
  {
    label: 'My Cards',
    route: '/account/dashboard/cards',
    show: 'auction',
  },
  {
    label: 'Invoices',
    route: '/account/dashboard/invoices',
    show: 'auction',
  },
  {
    label: 'Receipts',
    route: '/account/dashboard/receipts',
    show: 'auction',
  },
  {
    label: 'Pickup Helper',
    route: '/account/dashboard/picklist',
    show: 'auction',
  },
  {
    label: 'Notifications',
    route: '/account/dashboard/notifications',
    show: 'auction',
  },
  {
    label: 'My Profile',
    route: '/account/dashboard/profile',
    show: 'auction',
  },
  {
    label: 'Checkout QR Code',
    route: '/account/dashboard/checkout-qr-code',
    show: 'auction',
  },
  {
    label: 'Offers',
    route: '/account/dashboard/offers',
    show: 'auction',
  },
  {
    label: 'Credits',
    route: '/account/dashboard/credits',
    show: 'auction',
  },
  {
    label: 'My Saved Searches',
    route: '/account/dashboard/saved-searches',
    show: 'auction',
  },
]

export default MyAccount
