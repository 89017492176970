type PropTypes = {
  height: number | string
  width?: number | string
  color?: string
  children: any
  absolute?: boolean
}

const BackgroundLayout = ({
  height,
  width = 'full',
  absolute = true,
  color = 'bidfta-blue-backdrop-light',
  children,
}: PropTypes) => {
  return (
    <div style={{ height: height }}>
      <div
        className={`items-center ${
          absolute ? 'absolute left-0' : ''
        } w-${width} bg-${color} z-0`}
        style={{ height: height }}
      />
      <div className={'relative h-full'}>{children}</div>
    </div>
  )
}

export default BackgroundLayout
