import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import transformCategoryToSlug from '../transformCategoryToSlug'
import useNextQuery from './useNextQuery'
import getPageNumberFromPath from '../functions/getPageNumberFromPath'

const useAuctionRoute = (shouldPushRoute: boolean = true) => {
  const router = useRouter()
  const sections = router.asPath.split('/')
  const query = useNextQuery()
  const [routeState, setRouteState] = useState<{
    base: string
    category: string
    page: number
  }>({
    base: sections[1],
    category: sections[2],
    page: getPageNumberFromPath(router.asPath) || 1,
  })
  const { base, category, page } = routeState

  useEffect(() => {
    if (base && shouldPushRoute) {
      router.push(
        { pathname: router.pathname, query: query.queries },
        {
          pathname: `/${base}/${category}/${page}`,
          query: query.queries,
        },
        { shallow: true, scroll: true }
      )
    }
    if (
      router.pathname?.includes('/category') ||
      router.pathname?.includes('/past-auctions') ||
      router.pathname?.includes('/mnr-auctions')
    ) {
      localStorage.setItem('lastKnownFilteredAuctionPage', router.asPath)
    }
  }, [routeState])

  useEffect(() => {
    const sections = router.asPath.split('/')
    setRouteState({
      base: sections[1],
      category: sections[2],
      page: getPageNumberFromPath(router.asPath) || 1,
    })
  }, [router.asPath])

  const updateRoute = (name: string, value: string | number) => {
    setRouteState((prevState) => ({ ...prevState, [name]: value }))
  }

  const changeCategory = (val: string) => {
    const sections = router.asPath.split('/')
    let newCategory =
      val === 'Categories - All' ? 'all' : transformCategoryToSlug(val)
    setRouteState({
      base: sections[1],
      category: newCategory,
      page: 1,
    })
  }
  const changePage = (val: number) => {
    updateRoute('page', val)
  }

  const clear = (val = null) => {
    router.push(
      { pathname: router.pathname, query: {} },
      { pathname: `/${val || base}/all/1`, query: {} },
      { shallow: true }
    )
  }

  const changeBase = (val: string) => {
    if (val !== base) {
      clear()
    }
  }

  const route = {
    base,
    category,
    page,
    changeBase,
    changeCategory,
    changePage,
    clear,
  }

  return {
    query,
    route,
  }
}

export default useAuctionRoute
