import React from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import useSearch from '@components/Items/utils/hooks/useSearch'
import classNames from 'classnames'

type SearchProps = {
  titleView?: boolean
}

type FormInputs = {
  textInput: string
}

const Search = ({ titleView }: SearchProps) => {
  const [search, setSearch] = useSearch()
  const { register, watch, setValue } = useForm<FormInputs>({
    defaultValues: { textInput: search },
  })

  const containerClasses = titleView ? 'text-2xl w-full grow' : ''
  const inputClasses = titleView ? 'text-lg' : 'text-sm'

  const onSearch = () => {
    setSearch(watch('textInput'))
  }

  const handleClear = () => {
    setValue('textInput', '')
    setSearch('')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      ['Enter', 'Return', 'Done', 'Go', 'Next', 'Search', 'Send'].includes(
        e.key
      )
    ) {
      onSearch()
    }
  }

  return (
    <div className={classNames('relative flex items-center', containerClasses)}>
      <div className="relative w-full">
        <input
          id="default-search"
          className={`w-full block py-1 pl-1 pr-10 border border-gray-300 rounded-md bg-gray-50 ${inputClasses}`}
          placeholder="What are you searching for today?"
          onKeyDown={handleKeyPress}
          {...register('textInput')}
        />
        <button
          type="button"
          className="absolute right-0 top-0 bottom-0 px-3 py-1 bg-transparent text-gray-500"
          onClick={handleClear}
        >
          <AiOutlineClose />
        </button>
      </div>
    </div>
  )
}

export default Search
