import { ReactNode, useContext } from 'react'
import Footer from './Footer'
import styles from './MainLayout.module.scss'
import { GlobalUIContext } from '../../contexts/GlobalUIContext'
import { useRouter } from 'next/router'
import SeoHead from './SeoHead'
import Header from './Header'
import Sidebar from '@components/MainLayout/Sidebar'
import { useNavigation } from '../../contexts/NavigationContext'

type MainLayoutType = {
  children: ReactNode
  title?: string
  previewImage?: string
  previewDescription?: string
  showFilter?: boolean
  filterData?: any
  hideOverflow?: boolean
  ignoreGlobalSEO?: boolean
}

const MainLayout = function ({
  children,
  title = 'BidFTA',
  previewImage = `${process.env.NEXT_PUBLIC_URL}/images/default-preview-card.png`,
  previewDescription = 'Turn your wants into wins with BidFTA Online Auctions',
  showFilter,
  filterData,
  hideOverflow = true,
  ignoreGlobalSEO = false,
}: MainLayoutType) {
  const { mainOverflowY } = useContext<any>(GlobalUIContext) || {}

  const { showSidebar, setShowSidebar } = useNavigation()

  const { asPath, pathname } = useRouter()

  return (
    <>
      {ignoreGlobalSEO ? (
        <></>
      ) : (
        <SeoHead
          title={title}
          previewDescription={previewDescription}
          previewImage={previewImage}
        />
      )}
      <Header showFilter={showFilter} {...filterData} />
      {showSidebar && (
        <button
          className={`${styles.fadeMe}`}
          onClick={() => {
            setShowSidebar(false)
          }}
          style={{ zIndex: 50 }}
        />
      )}
      <Sidebar />
      <div>
        <main
          style={{ backgroundColor: asPath === '/' ? '' : '#f5f5f5' }}
          className={`${styles.main_container} w-full relative ${
            hideOverflow ? '' : ''
          } ${hideOverflow && mainOverflowY ? 'overflow-y-hidden' : ''} ${
            ![
              '/',
              '/bidfta-plus',
              '/bin-store',
              '/bin-store#locations',
              '/campaign',
              '/campaign/[slug]',
              '/info',
              '/info/[slug]',
            ].includes(pathname)
              ? 'main-container'
              : ''
          }`}
        >
          <div
            className={`${
              ![
                '/',
                '/bidfta-plus',
                '/bin-store',
                '/bin-store#locations',
                '/[auctionId]/item-detail/[itemId]',
                '/blog',
                '/blog/[slug]',
                '/campaign/[slug]',
                '/info/[slug]',
              ].includes(pathname)
                ? 'mobile-padding'
                : ''
            }`}
          >
            {children}
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
