import Link from 'next/link'

const Button = ({ disabled, onClick, href, children }: any) => {
  if (href)
    return (
      <Link
        href={href}
        className="bg-bidfta-blue-backdrop-dark hover:bg-bidfta-blue-backdrop-light shadow font-bold py-2 rounded font-bold text-white w-full text-center"
      >
        {children}
      </Link>
    )

  return (
    <button
      className={`bg-bidfta-blue-backdrop-dark hover:bg-bidfta-blue-backdrop-light shadow font-bold py-2 rounded font-bold text-white w-full`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
