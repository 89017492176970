import { useEffect, useContext } from 'react'
import BidLocationState from './BidLocationState/BidLocationState'
import styles from './BidLocationFilter.module.scss'
import { GlobalUIContext } from '../../../../../../contexts/GlobalUIContext'

export default function BidLocationFilter({
  locationGroupState,
  locations,
  addQuery,
  reduceQuery,
}: any) {
  const { setCondensedFooter } = useContext<any>(GlobalUIContext)

  useEffect(() => {
    setCondensedFooter(true)
    return () => {
      setCondensedFooter(false)
    }
  }, [setCondensedFooter])

  return (
    <div className={'relative z-10'}>
      <div
        style={{ maxHeight: '75vh' }}
        className={`bg-white p-4 border border-bidfta-gray-muted overflow-y-scroll`}
      >
        <ul className={`${styles.location_list} location_list`}>
          {Object.entries(locationGroupState).map((location: any) => (
            <BidLocationState
              key={location}
              location={location[1] || {}}
              listName={location[0]}
              locations={locations}
              addQuery={addQuery}
              reduceQuery={reduceQuery}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
