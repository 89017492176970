import React, { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'

import Title from '@components/MainLayout/Sidebar/reusable/Title'
import { useNavigation } from 'contexts/NavigationContext'
import ZipcodeForm, {
  FormData,
} from '@components/MainLayout/Sidebar/reusable/ZipcodeForm'

const UpdateLocation = () => {
  const { zipcode, setZipcode, handlePreferredZipcode, setShowSidebar } =
    useNavigation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setErrorMessage(null)
    try {
      const isSuccess = await handlePreferredZipcode(data.zipcode)
      if (isSuccess) {
        setZipcode(data.zipcode)
        setShowSidebar(false)
      } else {
        setErrorMessage('Failed to update locations')
      }
    } catch (error) {
      setErrorMessage('Error updating locations: ' + (error as Error).message)
    }
  }

  return (
    <>
      <Title>Update Location</Title>
      <ZipcodeForm
        zipcode={zipcode}
        onSubmit={onSubmit}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default UpdateLocation
