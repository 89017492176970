import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import transformSlugToCategory from '@utilities/transformSlugToCategory'

const useGtmAuctionCategoryEvent = (slug: string) => {
  useEffect(() => {
    try {
      if (slug === 'all') {
        return
      }
      const category = transformSlugToCategory(slug)
      if (!category) {
        return
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'handle-auction-category',
          'data-auction-category': category,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }, [slug])
}

export default useGtmAuctionCategoryEvent
